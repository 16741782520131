import React from "react";

const BasePage = () => {
  return (
    <div>
      <h1>Welcome to the Zillow Comment Page</h1>
    </div>
  );
};

export default BasePage;
